import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

// Components
import LinkCard from './LinkCard'

const LinkContainer = styled.div`
  background: ${({ theme }) => theme.colors.color4};
`

const LinkReset = styled(Link)`
  text-decoration: none !important;
`

const HomeLinks = () => {
  const data = useStaticQuery(graphql`
    {
      allDocLinksYaml {
        edges {
          node {
            title
            icon
            id
            text
            items {
              link
            }
          }
        }
      }
    }
  `)

  const docLinks = data?.allDocLinksYaml?.edges

  return (
    <LinkContainer className="py-5">
      <Container>
        <Row className="mx-0 mx-md-5">
          {docLinks.map(doc => {
            const { title, id, icon, text, items } = doc.node
            return (
              <Col key={id} xs={12} lg={6} className="my-2 my-md-3">
                <LinkReset to={`docs${items[0].link}`}>
                  <LinkCard title={title} icon={icon} text={text} />
                </LinkReset>
              </Col>
            )
          })}
        </Row>
      </Container>
    </LinkContainer>
  )
}

export default HomeLinks
