import React from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'

// Components
import HeroContent from './HeroContent'
import HeroIcon from './HeroIcon'

const HeroContainer = styled(Container)`
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-left: ${({ theme }) => theme.spacings.large} !important;
    padding-right: ${({ theme }) => theme.spacings.large} !important;
  }
  @media only screen and (min-width: 961px) and (max-width: 991px) {
    padding-left: ${({ theme }) => theme.spacings.large} !important;
    padding-right: ${({ theme }) => theme.spacings.large} !important;
    max-width: 100% !important;
  }
`

const Hero = () => {
  return (
    <>
      <HeroContainer className="py-5">
        <HeroContent />
        <HeroIcon />
      </HeroContainer>
    </>
  )
}

export default Hero
