import React from 'react'
import PropTypes from 'prop-types'

// Components
import Hero from '../components/Hero'
import HomeLinks from '../components/HomeLinks'
import Layout from '../components/Layout'

const HomePage = ({ location }) => {
  return (
    <Layout location={location} seo={{ title: 'Home' }}>
      <Hero />
      <HomeLinks />
    </Layout>
  )
}

HomePage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default HomePage
