import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext } from 'styled-components'

// React Icons
import { IconContext } from 'react-icons/lib'
import { BsPlay, BsArchive, BsCodeSlash, BsImage } from 'react-icons/bs'
import { BiBookAlt, BiFontFamily } from 'react-icons/bi'
import { FiSliders, FiType, FiNavigation2 } from 'react-icons/fi'

const switcher = name => {
  switch (name) {
    case 'BsPlay':
      return <BsPlay />
    case 'BsArchive':
      return <BsArchive />
    case 'BiBookAlt':
      return <BiBookAlt />
    case 'BsCodeSlash':
      return <BsCodeSlash />
    case 'FiSliders':
      return <FiSliders />
    case 'BiFontFamily':
      return <BiFontFamily />
    case 'BsImage':
      return <BsImage />
    case 'FiType':
      return <FiType />
    case 'FiNavigation2':
      return <FiNavigation2 />
    default:
      return
  }
}

const Icon = ({ name }) => {
  const themeContext = useContext(ThemeContext)
  return (
    <IconContext.Provider
      value={{ color: themeContext.colors.color1, size: themeContext.spacings.large }}
    >
      {switcher(name)}
    </IconContext.Provider>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Icon
