import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card } from 'react-bootstrap'

// Components
import Icon from './Icon'

const CardContainer = styled(Card)`
  border: none !important;
  /* box-shadow: 0px 0px 2px 1px ${({ theme }) => theme.colors.color4}; */
  box-shadow: 1px 2px 3px 4px rgba(241, 239, 249, 0.5);
  display: flex;
  flex-direction: row;
  /* align-content: center;
  justify-content: center;
  align-self: center;
  align-items: center; */
`

const CardIconContainer = styled.div`
  align-content: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacings.xSmall};
`

const CardBody = styled(Card.Body)`
  display: flex;
  flex-direction: column;
`

const CardTitle = styled(Card.Title)`
  color: ${({ theme }) => theme.colors.color1};
`

const CardText = styled(Card.Text)`
  color: ${({ theme }) => theme.colors.color3};
  opacity: 0.7;
`

const LinkCard = ({ title, icon, text }) => {
  return (
    <CardContainer className="p-3 py-md-4 px-md-5 d-flex align-items-center flex-row h-100 w-100">
      <CardIconContainer>
        <Icon name={icon} />
      </CardIconContainer>
      <CardBody className="flex-fill">
        <CardTitle>{title}</CardTitle>
        <CardText>{text}</CardText>
      </CardBody>
    </CardContainer>
  )
}

LinkCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default LinkCard
