import React from 'react'
import styled from 'styled-components'

const IconContainer = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.spacings.large};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
    visibility: hidden;
  }
`

const IconSvg = styled.svg`
  display: flex;
  height: 18rem;
`

const HeroIcon = () => {
  return (
    <IconContainer>
      <IconSvg viewBox="0 0 100 86.2">
        <g>
          <path
            d="M95.9,28.1h-5.7V13.2c0-6.9-5.6-12.6-12.6-12.6H13.5C6.6,0.6,0.9,6.3,0.9,13.2v41.2C0.9,61.4,6.6,67,13.5,67
		h46.9v14.9c0,1.9,1.5,3.4,3.4,3.4h32c1.9,0,3.4-1.5,3.4-3.4V31.5C99.3,29.6,97.8,28.1,95.9,28.1z M13.5,60.1
		c-3.2,0-5.7-2.6-5.7-5.7V13.2c0-3.2,2.6-5.7,5.7-5.7h64.1c3.2,0,5.7,2.6,5.7,5.7v14.9H63.9c-1.9,0-3.4,1.5-3.4,3.4v28.6H13.5z
		 M92.5,78.4H67.3V35h25.2V78.4z"
          />
          <path d="M50.1,73.9H22.7c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4h27.5c1.9,0,3.4-1.5,3.4-3.4S52,73.9,50.1,73.9z" />
        </g>
      </IconSvg>
    </IconContainer>
  )
}

export default HeroIcon
