import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

const NormalButton = styled(Button)`
  font-size: ${({ theme }) => theme.desktopSizes.p} !important;
  background: ${({ theme }) => theme.colors.color1} !important;
  border-color: ${({ theme }) => theme.colors.color1} !important;
  margin-right: ${({ theme }) => theme.spacings.small} !important;
`

const InvertButton = styled(Button)`
  color: ${({ theme }) => theme.colors.color1} !important;
  font-size: ${({ theme }) => theme.desktopSizes.p} !important;
  background: ${({ theme }) => theme.colors.color6} !important;
  border-color: ${({ theme }) => theme.colors.color5} !important;
  outline: 0;
  shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`

const BtnDefault = ({ href, invert, children, ...props }) => {
  return invert ? (
    <InvertButton {...props}>{children}</InvertButton>
  ) : (
    <NormalButton {...props}>{children}</NormalButton>
  )
}

BtnDefault.defaultProps = {
  invert: false,
}

BtnDefault.propTypes = {
  invert: PropTypes.bool,
}

export default BtnDefault
