import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'

// Components
import BtnDefault from '../Buttons/BtnDefault'

const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
`

const HeroH1 = styled.h1`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.color2};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    text-align: center;
  }
`

const HeroText = styled.p`
  color: ${({ theme }) => theme.colors.color3};
  opacity: 0.7;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    text-align: center;
  }
`

const HeroGroup = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const HeroContent = () => {
  const { site, docLinksYaml } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          description
        }
      }
      docLinksYaml {
        items {
          link
        }
      }
    }
  `)

  const desc = site?.siteMetadata?.description
  const firstLink = docLinksYaml?.items[0]?.link

  return (
    <HeroContainer>
      <HeroH1>{desc}</HeroH1>
      <HeroText>
        This project aims to bring additional capabilities of development and unleash the potential
        using existing workflows and technologies with continuous development and strives to set the
        standard.
      </HeroText>
      <HeroGroup>
        <Link to={`/docs${firstLink}`}>
          <BtnDefault size="lg">Documentation</BtnDefault>
        </Link>
        <a href="https://github.com/epubknowledge" target="_blank" rel="noopener noreferrer">
          <BtnDefault size="lg" invert={true}>
            Github
          </BtnDefault>
        </a>
      </HeroGroup>
    </HeroContainer>
  )
}

export default HeroContent
